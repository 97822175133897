import {Injectable} from '@angular/core';
import {FormAutoSaveSerializerService} from './form-auto-save-serializer.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AutoSaveFormDataModel} from './declarations';
import {AutoSaveControllerService, AutoSaveDto} from '@matchsource/api-generated/common';
import flow from 'lodash-es/flow';
import {AutoSaveFormTypeCode} from '@matchsource/models/shared';
import {skipError, skipSpinner} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class FormAutoSaveApiService {
  constructor(
    private readonly autoSaveControllerService: AutoSaveControllerService,
    private readonly serializer: FormAutoSaveSerializerService
  ) {}

  getForm(formTypeCode: AutoSaveFormTypeCode): Observable<AutoSaveFormDataModel> {
    return this.autoSaveControllerService
      .get1(
        {typeCode: formTypeCode},
        {
          context: flow(skipSpinner, skipError)(),
        }
      )
      .pipe(map(result => this.serializer.fromDTO(result)));
  }

  // BE will create the form if doesn't exists. So we just need a PUT request
  updateForm(formData: AutoSaveFormDataModel): Observable<AutoSaveDto> {
    return this.autoSaveControllerService.updateOrCreateIfNotExist1(
      {body: this.serializer.toDTO(formData)},
      {
        context: flow(skipSpinner, skipError)(),
      }
    );
  }

  deleteForm(formTypeCode: AutoSaveFormTypeCode): Observable<void> {
    return this.autoSaveControllerService.delete(
      {typeCode: formTypeCode},
      {
        context: flow(skipSpinner, skipError)(),
      }
    );
  }
}
