import {Injectable} from '@angular/core';
import {Serializer} from '@matchsource/api-utils';
import {AutoSaveFormDataModel} from './declarations';
import {AutoSaveDto} from '@matchsource/api-generated/common';
import {AutoSaveFormTypeCode} from '@matchsource/models/shared';

interface AutoSavedJsonModel {
  urlParams: Record<string, unknown>;
  formBody: Record<string, any>;
}
@Injectable({
  providedIn: 'root',
})
export class FormAutoSaveSerializerService implements Serializer<AutoSaveFormDataModel, AutoSaveDto> {
  fromDTO(input: AutoSaveDto): AutoSaveFormDataModel {
    if (!input) {
      return null;
    }
    const parsedJson: AutoSavedJsonModel = JSON.parse(input.formJson);

    return {
      formTypeCode: input.typeCode as AutoSaveFormTypeCode,
      urlParams: parsedJson.urlParams,
      formBody: parsedJson.formBody,
    };
  }

  toDTO(input: AutoSaveFormDataModel): AutoSaveDto {
    return {
      typeCode: input.formTypeCode,
      formJson: JSON.stringify({
        formBody: input.formBody,
        urlParams: input.urlParams,
      }),
    };
  }
}
