import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  DeleteAutoSavedForm,
  EnableAutoSave,
  GetAutoSavedForm,
  SaveOrUpdateAutoSavedForm,
  SetAutoSaveRedirecting,
} from './auto-save.actions';
import {Injectable} from '@angular/core';
import {FormAutoSaveApiService, AutoSaveFormDataModel} from '@matchsource/api/form-auto-save';
import {of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

interface AutoSaveFormStateModel {
  loading: boolean;
  loaded: boolean;
  form: AutoSaveFormDataModel;
  initialLoadChange: boolean;
  autoSaveEnabled: boolean;
  redirecting: boolean;
}

@State<AutoSaveFormStateModel>({
  name: 'autoSave',
  defaults: {
    loading: false,
    loaded: false,
    form: null,
    redirecting: false,
    initialLoadChange: false,
    autoSaveEnabled: true,
  },
})
@Injectable()
export class AutoSaveState {
  @Selector([AutoSaveState])
  static autoSavedForm(state: AutoSaveFormStateModel) {
    return state.form;
  }

  @Selector([AutoSaveState])
  static loading(state: AutoSaveFormStateModel) {
    return state.loading;
  }

  @Selector([AutoSaveState])
  static loaded(state: AutoSaveFormStateModel) {
    return state.loaded;
  }

  @Selector([AutoSaveState])
  static initialLoadChange(state: AutoSaveFormStateModel) {
    return state.initialLoadChange;
  }

  @Selector([AutoSaveState])
  static redirecting(state: AutoSaveFormStateModel) {
    return state.redirecting;
  }

  @Selector([AutoSaveState])
  static enabled(state: AutoSaveFormStateModel) {
    return state.autoSaveEnabled;
  }

  constructor(private readonly formAutoSaveApiService: FormAutoSaveApiService) {}

  @Action(GetAutoSavedForm)
  getForm(ctx: StateContext<AutoSaveFormStateModel>, {formTypeCode}: GetAutoSavedForm) {
    return this.formAutoSaveApiService.getForm(formTypeCode).pipe(
      tap(response => {
        if (response) {
          ctx.patchState({
            form: response,
            loading: false,
            loaded: true,
            initialLoadChange: true,
          });
        } else {
          ctx.patchState({
            form: {
              formTypeCode,
              formBody: null,
              urlParams: null,
            },
            initialLoadChange: true,
            loading: false,
            loaded: true,
          });
        }
      }),
      catchError(() => {
        ctx.patchState({
          form: {
            formTypeCode,
            formBody: null,
            urlParams: null,
          },
          initialLoadChange: true,
          loading: false,
          loaded: true,
        });
        return of(null);
      })
    );
  }

  @Action(SaveOrUpdateAutoSavedForm)
  saveOrUpdateForm(
    ctx: StateContext<AutoSaveFormStateModel>,
    {formTypeCode, formBody, urlParams}: SaveOrUpdateAutoSavedForm
  ) {
    const formData = {formTypeCode, urlParams, formBody};

    ctx.patchState({
      initialLoadChange: false,
    });

    // BE will create the form if doesn't exists. So we just do a PUT request
    return this.formAutoSaveApiService.updateForm(formData).pipe(
      tap(() => {
        ctx.patchState({
          form: formData,
          initialLoadChange: false,
        });
      })
    );
  }

  @Action(EnableAutoSave)
  enableAutoSave(ctx: StateContext<AutoSaveFormStateModel>, {enabled}: EnableAutoSave) {
    ctx.patchState({
      autoSaveEnabled: enabled,
    });
  }

  @Action(DeleteAutoSavedForm)
  deleteAutoSavedForm(ctx: StateContext<AutoSaveFormStateModel>, {formTypeCode, disableAutoSave}: DeleteAutoSavedForm) {
    // Prevent new auto-saves after form gets deleted
    ctx.patchState({
      autoSaveEnabled: !disableAutoSave,
    });

    return this.formAutoSaveApiService.deleteForm(formTypeCode).pipe(
      tap(() => {
        ctx.patchState({
          form: {
            formTypeCode,
            formBody: null,
            urlParams: null,
          },
          loading: false,
          loaded: false,
        });
      })
    );
  }

  @Action(SetAutoSaveRedirecting)
  acceptAutoSavedData(ctx: StateContext<AutoSaveFormStateModel>, {redirecting}: SetAutoSaveRedirecting) {
    ctx.patchState({
      redirecting,
    });
  }
}
