import {AutoSaveFormTypeCode} from '@matchsource/models/shared';

export class GetAutoSavedForm {
  static readonly type = '[AutoSave] Get Form';

  constructor(public readonly formTypeCode: AutoSaveFormTypeCode) {}
}

export class SaveOrUpdateAutoSavedForm {
  static readonly type = '[AutoSave] Save or Update Form';

  constructor(
    public readonly formTypeCode: AutoSaveFormTypeCode,
    public readonly formBody: any,
    public readonly urlParams: any
  ) {}
}

export class DeleteAutoSavedForm {
  static readonly type = '[AutoSave] Delete Form';

  constructor(
    public readonly formTypeCode: AutoSaveFormTypeCode,
    public readonly disableAutoSave: boolean
  ) {}
}

export class EnableAutoSave {
  static readonly type = '[AutoSave] Enable';

  constructor(public readonly enabled: boolean) {}
}

export class SetAutoSaveRedirecting {
  static readonly type = '[AutoSave] Redirecting';

  constructor(public readonly redirecting: boolean) {}
}
